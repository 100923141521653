import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import PrimaryBox from 'components/Advanced_User/All_BlueIris_Tutorials/PrimaryBox';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MqttTimeLine = makeShortcode("MqttTimeLine");
const MqttIftttTimeLine = makeShortcode("MqttIftttTimeLine");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='BlueIris Articles for Developers' dateChanged='2024-04-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='Adding your INSTAR IP Camera to your BlueIris Surveillance System.' image='/images/Search/P_SearchThumb_Blue_Iris.png' twitter='/images/Search/P_SearchThumb_Blue_Iris.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/All_BlueIris_Tutorials/' locationFR='/fr/Advanced_User/All_BlueIris_Tutorials/' crumbLabel="BlueIris" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <MqttTimeLine mdxType="MqttTimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <MqttIftttTimeLine mdxType="MqttIftttTimeLine" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      